"use client"

import { useEffect, useState } from "react"

export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  )

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", updateWindowWidth)

    return () => window.removeEventListener("resize", updateWindowWidth)
  }, [])

  return { windowWidth }
}
