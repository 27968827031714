"use client"
import { countriesLabels } from "./data"
import { useI10n } from "./useI10n"
import { DropDown } from "@local/ui/src/DropDown"
import type { DropDownValueType } from "@local/ui/src/DropDown"
import { useCart } from "@local/cart/src/useCart"
import { classNames } from "@local/utils"
import { flagCodeToPng } from "@local/utils/src/flagCodeToPng"

export const RegionSelector = ({
  transparent,
  hidden,
  id,
  className,
  wrapperClassName,
}: {
  hidden?: boolean
  id?: string
  className?: string
  wrapperClassName?: string
  transparent?: boolean
}) => {
  const { init, country } = useI10n()
  const { reset } = useCart()

  const changeRegion = (e: string) => {
    const { country, currency } = init(e, true)
    reset()
    const newParams = new URL(window.location.href)
    newParams.searchParams.set("country", country)
    newParams.searchParams.set("currency", currency)
    history.replaceState({}, "", newParams)
    window.location.reload()
  }

  const parsedCountriesLabels = [] as DropDownValueType[]
  countriesLabels.map((country) =>
    parsedCountriesLabels.push({
      value: country[0],
      label: country[1],
      startIcon: flagCodeToPng(country[0]),
      outerLabel: (
        <span className="flex gap-2 items-center p-2">
          {flagCodeToPng(country[0])}
          {country[1]}
        </span>
      ),
    })
  )

  if (hidden) {
    return null
  }

  return (
    <DropDown
      selected={country}
      onChange={changeRegion}
      options={parsedCountriesLabels}
      positionTop
      id={id}
      className={classNames(className ?? "", transparent ? "!shadow-none" : "")}
      wrapperClassName={wrapperClassName}
    />
  )
}
