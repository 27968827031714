import Image from "next/image"

export const flagCodeToPng = (code: string) => {
  return (
    <Image
      src={`https://flagcdn.com/24x18/${code.toLowerCase()}.png`}
      width={24}
      height={18}
      alt="flag"
      className="w-5 h-4 inline-block"
    />
  )
}
