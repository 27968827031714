"use client"
import { useI10n } from "./useI10n"

interface Props {
  value?: number | null
}

export const Price = ({ value }: Props) => {
  const { currencySymbol, priceDecimals } = useI10n()

  if (value == null || isNaN(value)) {
    return null
  }

  return (
    <>
      {/* zero-width no-break-space */}
      {currencySymbol}&#8288;
      {(value / 100).toFixed(priceDecimals)}
    </>
  )
}
