"use client"

import { memo, useId } from "react"
import type { RecoverResponse } from "./useIP"
import { isBrowser } from "@local/utils/src/isBrowser"
// import { handleViewport } from "react-in-viewport"
import Cookies from "js-cookie"
import Script from "next/script"
import { create } from "zustand"
import { getTrackingCookies } from "./getTrackingCookies"
import { cleanDomain } from "@local/utils/src/cleanDomain"
import { sendBacon } from "@local/utils/src/sendBeacon"
import { objectToShiftedBase64 } from "@local/utils/src/safeBase64"
import { getRecoveryStorage } from "./getRecoveryStorage"

// https://developers.google.com/tag-platform/tag-manager/web/datalayer
// https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#data-layer

export const baseParametersForTracking = () => {
  if (!isBrowser()) {
    return null
  }

  const rData: RecoverResponse | undefined = window.$r
  const today = new Date()
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]

  const { chills, ga, gtmUpi, vibes, exponeaId } = getTrackingCookies()
  const { bot, ip, loc, geo, ua, weather } = getRecoveryStorage()

  const country = Cookies.get("country")
  const currency = Cookies.get("div-currency")
  const locale = Cookies.get("div-lang")
  const vvv = Cookies.get("vvvtwo")

  const google_analytics = ga
    ? ga.split(".").slice(2, 3).join() + "." + ga.split(".").slice(3, 4).join()
    : ""

  const pageType = ["baerskintactical", "hamstore", "cozistore"].includes(
    window.channel ?? (process.env.NEXT_PUBLIC_CHANNEL_ID as string)
  )
    ? "mini"
    : "anchor"

  return {
    approx_zip_code: loc?.zip ?? rData?.loc?.zip,
    browser_name: ua?.browser.name ?? rData?.ua?.browser.name,
    browser_language: navigator.language.split("-")[0].toLowerCase(),
    browser_language_and_locale: navigator.language.toLowerCase(),
    browser_type: ua?.device.type ?? rData?.ua?.device.type ?? "desktop",
    checkout_language: locale,
    checkout_locale: country,
    chills: chills ?? rData?.chills,
    city: geo?.city ?? loc?.city ?? rData?.loc?.city,
    connection_type: loc ? (loc.mobile ? "cellular" : "wifi") : "unknown",
    cookie: exponeaId ?? window?.$cdata?.id?.exponea_id,
    country: geo?.country ?? loc?.country ?? rData?.loc?.country,
    currency_checkout: currency,
    day_of_week_local: daysOfWeek[today.getDay()],
    device_brand: ua?.device.vendor ?? rData?.ua?.device.vendor,
    device: ua?.device.model ?? rData?.ua?.device.model,
    facebook_click_id: new URLSearchParams(window.location.search).has("fbclid")
      ? new URLSearchParams(window.location.search).get("fbclid")?.split("#")[0]
      : null,
    google_analytics_id: google_analytics,
    google_click_id: new URLSearchParams(window.location.search).has("gclid")
      ? new URLSearchParams(window.location.search).get("gclid")?.split("#")[0]
      : null,
    hour_local: today.getHours(),
    internet_provider: loc?.isp ?? rData?.loc?.isp,
    ip: ip ?? rData?.ip,
    isBot: bot ?? rData?.bot,
    is_mobile_device: `${/iphone|ipod|android|ie|blackberry|fennec/.test(
      ua?.ua || rData?.ua?.ua.toLowerCase() || navigator.userAgent.toLowerCase()
    )}`,
    latitude: geo?.latitude ?? loc?.lat ?? rData?.loc?.lat,
    location: document.location.href || window.location.href,
    longitude: geo?.longitude ?? loc?.lon ?? rData?.loc?.lon,
    os: ua?.os.name ?? rData?.ua?.os.name,
    page_title: document.title,
    page_version: pageType,
    page_visit_unique_id: rData?.chills
      ? rData.chills + ".p" + today.valueOf()
      : undefined,
    path: window.location.pathname,
    proxy: loc?.proxy ? true : false,
    url_host_name: window.location.hostname,
    url_main: cleanDomain(window.location.hostname),
    url_parameters: `${JSON.stringify(
      window.location.search
        .replace("?", "")
        .split("&")
        .map((param) => param.replace("=", ": "))
    )}`,
    user_is_bot: bot ? bot : false,
    region: geo?.region ?? loc?.regionName ?? rData?.loc?.regionName,
    referrer: document.referrer ? document.referrer : null,
    referrer_host_name: document.referrer
      ? new URL(document.referrer).hostname
      : null,
    release:
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ??
      process.env.NEXT_PUBLIC_COHERENCE_GIT_COMMIT_SHA ??
      null,
    screen_size: `${window.screen.width}x${window.screen.height}`,
    session_id: rData?.chills
      ? rData.chills + ".p" + today.valueOf()
      : undefined,
    split_test: vvv ?? null,
    temperature: weather?.temp ?? rData?.w?.temp ?? null,
    timestamp: today.toISOString(),
    timezone: geo?.timezone ?? loc?.timezone ?? rData?.loc?.timezone,
    user_agent: ua?.ua || rData?.ua?.ua || navigator.userAgent,
    user_anonymous_id: gtmUpi,
    utm_campaign: `${
      new URLSearchParams(window.location.search).has("utm_campaign")
        ? new URLSearchParams(window.location.search)
            .get("utm_campaign")
            ?.split("#")[0]
        : null
    }`,
    utm_content: `${
      new URLSearchParams(window.location.search).has("utm_content")
        ? new URLSearchParams(window.location.search)
            .get("utm_content")
            ?.split("#")[0]
        : null
    }`,
    utm_medium: `${
      new URLSearchParams(window.location.search).has("utm_medium")
        ? new URLSearchParams(window.location.search)
            .get("utm_medium")
            ?.split("#")[0]
        : null
    }`,
    utm_source: `${
      new URLSearchParams(window.location.search).has("utm_source")
        ? new URLSearchParams(window.location.search)
            .get("utm_source")
            ?.split("#")[0]
        : null
    }`,
    utm_term: new URLSearchParams(window.location.search).has("utm_term")
      ? new URLSearchParams(window.location.search)
          .get("utm_term")
          ?.split("#")[0]
      : null,
    vibes: vibes ?? rData?.vibes,
    weather_description: weather?.main ?? rData?.w?.main ?? null,
    weather_description_specific:
      weather?.description ?? rData?.w?.description ?? null,
  }
}

export const trackAction = (
  name: string,
  data?: any,
  specific?: {
    exp?: boolean
    ga?: boolean
  }
) => {
  const { vibes } = getTrackingCookies()
  const exp = specific?.hasOwnProperty("exp") ? specific.exp : true
  const ga = specific?.hasOwnProperty("ga") ? specific.ga : true

  if (isBrowser() && window.gtag) {
    if (!specific || ga === true) {
      window.gtag("event", name, {
        ...data,
        vibes,
      })
    }
    if (window.exponea && window.exponea.track && (!specific || exp === true)) {
      window.exponea.track(name, {
        ...data,
        vibes: name !== "consent" ? vibes : undefined,
      })
    }

    // Hotjar event that only happens in BST
    // if (
    //   process.env.NEXT_PUBLIC_CHANNEL_ID === "baerskintactical" &&
    //   !specific
    // ) {
    //   if (window.hj) {
    //     window.hj("event", name)
    //   }
    // }
  }

  if (!specific || exp === false) {
    const endpointDomain = `${process.env.NEXT_PUBLIC_APP_URL}/api`
    const endpoint = `${endpointDomain}/t/`
    const body = {
      channelId:
        window.channel ?? (process.env.NEXT_PUBLIC_CHANNEL_ID as string),
      data,
      name,
      user: baseParametersForTracking(),
      vibes,
    }

    const encodedBody = objectToShiftedBase64(body, 24)
    const blob = new Blob([encodedBody], {
      type: "text/plain",
    })
    sendBacon(endpoint, blob)
  }

  trackClarity(name)
}

export const useTracking = create<any>()((set, get) => ({
  buffered: {
    name: "",
    eventString: "",
  },
  trackActionBuffered: (name: string, data?: any, timeout: number = 3000) => {
    const currentStoredData = get().buffered.eventString
    const currentStoredName = get().buffered.name

    if (
      JSON.stringify(data) === currentStoredData &&
      name === currentStoredName
    ) {
      return
    } else {
      set({
        buffered: {
          eventString: JSON.stringify(data),
          name,
        },
      })
      setTimeout(() => {
        set({
          eventString: "",
          name: "",
        })
      }, timeout)
      if (name && data) {
        trackAction(name, data)
      } else {
        console.warn("trackAction buffer: name or data missing")
      }
    }
  },
}))

export const trackFbq = (name: string, data?: any) => {
  if (isBrowser() && window.fbq) {
    window.fbq("track", name, data)
  }
}

export const trackTtq = (name: string, data?: any) => {
  if (isBrowser() && window.ttq) {
    window.ttq.track(name, data)
  }
}

export const trackRumble = (name: string, data?: any) => {
  if (isBrowser() && window._ratagData) {
    window._ratagData.push(name, data)
  }
}

export const trackPinterest = (name: string, data?: any) => {
  if (isBrowser() && window.pintrk) {
    window.pintrk("track", name, data)
  }
}

export const trackTwitter = (name: string, data?: any) => {
  if (isBrowser() && window.twq) {
    window.twq("event", name, data)
  }
}

export const trackNbpix = () => {
  if (isBrowser() && window.nbpix) {
    window.nbpix("event", "add_to_cart")
  }
}

export const trackClarity = (name: string) => {
  if (isBrowser() && window.clarity) {
    window.clarity("event", name)
  }
}

// export const trackLiveIntent = (name: string, data?: any) => {
//   if (isBrowser() && window.liQ) {
//     window.liQ = window.liQ || []
//     window.liQ.push({
//       event: name,
//       ...data,
//     })
//   }
// }

export const trackSnapchat = (name: string, data?: any) => {
  if (isBrowser() && window.snaptr) {
    window.snaptr("track", name, data)
  }
}

const digestMessage = async (val?: string | null) => {
  if (!val) {
    return null
  }

  const msgUint8 = new TextEncoder().encode(val) // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8) // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("") // convert bytes to hex string
  return hashHex
}

interface TikTokIdentifyInput {
  email?: string | null
  phone?: string | null
}

export const identifyTtq = async ({ email, phone }: TikTokIdentifyInput) => {
  const userId = Cookies.get("gtm_upi")
  let hashedEmail = email
  let hashedPhone = phone

  try {
    if (isBrowser() && window.ttq) {
      /**
       * Hello, welcome to callback hell. This is those chains of promises that either we make it suck like this
       * Or we refactor half an application just to accomodate tracking on TikTok. So... There we go...
       * All of this because TikTok wants us to SHA256 all PII, but ironiaclly, we all know they don't care about
       * privacy. Well well well...
       */
      digestMessage(email).then((res) => {
        if (res) {
          hashedEmail = res
        }
        digestMessage(phone).then((res) => {
          if (res) {
            hashedPhone = res
          }
          digestMessage(userId).then((res) => {
            if (res) {
              window.ttq.identify({
                email: hashedEmail, // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
                phone_number: hashedPhone, // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
                external_id: res, // A unique ID from the advertiser such as user or external cookie IDs. It must be hashed with SHA256 on the client side.
              })
            }
          })
        })
      })
    }
  } catch (e) {
    console.error("ttq not loaded")
  }
}

export const TrackScript = ({ name, input }: { name: string; input?: any }) => {
  const id = useId()
  const { vibes } = getTrackingCookies()

  if (isBrowser() && window.gtag) {
    const data = JSON.stringify({
      ...input,
      vibes,
    })

    return (
      <Script
        id={`tr-script-action-${name}-${id}`}
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.gtag('event', '${name}', ${data});
          if ( window.exponea ) {
            window.exponea.track('${name}', ${data});
          }
        `,
        }}
      />
    )
  }
  return null
}

export const MemoTrackScript = memo(TrackScript)
