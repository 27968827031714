"use client"
import { ReactNode, useEffect, useState } from "react"
import { classNames } from "@local/utils/src/classNames"

export const Transition = ({
  show,
  enter,
  enterFrom,
  enterTo,
  leave,
  leaveFrom,
  leaveTo,
  children,
  className,
}: {
  show?: boolean
  enter?: string
  enterFrom?: string
  enterTo?: string
  leave?: string
  leaveFrom?: string
  leaveTo?: string
  children: ReactNode
  className?: string
}) => {
  const [entry, setEntry] = useState(false)
  const [initalState] = useState(show)
  const [showBuffer, setShowBuffer] = useState(show)

  useEffect(() => {
    setEntry(initalState === show)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const entryClassNames = classNames(
    enter ?? "",
    show ? enterTo ?? "" : enterFrom ?? ""
  )
  const leaveClassNames = classNames(
    leave ?? "",
    show ? leaveFrom ?? "" : leaveTo ?? ""
  )

  return (
    <div
      className={classNames(
        className ? className : "",
        entry ? entryClassNames : leaveClassNames
      )}
      onTransitionEnd={() => setShowBuffer(show)}
    >
      {(show || (!show && showBuffer)) && children}
    </div>
  )
}

export default Transition
